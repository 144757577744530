import { GetServerSideProps, NextPage } from "next";
import React from "react";

import App from "components/containers/App";
import AppBuilder from "components/containers/AppBuilder";
import { getWebsiteTitle } from "components/sections/Overview/utils";
import baseApi from "services/api";
import helpInfosApi from "services/api/helpInfos";
import userApi from "services/api/user";
import { appStoreWrapper } from "stores";
import { brandingUpdated } from "stores/features/appBuilder/branding";
import { overviewUpdated } from "stores/features/appBuilder/overview";
import { uiUpdated } from "stores/features/ui";
import api from "utils/api";
import { getCookieHeaders } from "utils/cookie";
import { useMobile } from "utils/hooks";

const ProjectNewPage: NextPage = () => {
  const isMobile = useMobile();

  return (
    <App authEnabled hotJarEnabled={!isMobile} compressContent>
      <AppBuilder />
    </App>
  );
};

export const getServerSideProps: GetServerSideProps =
  appStoreWrapper.getServerSideProps((store) => async (context) => {
    store.dispatch(helpInfosApi.endpoints.getHelpInfo.initiate());

    const headers = getCookieHeaders(context);
    store.dispatch(userApi.endpoints.getCurrentUser.initiate({ headers }));

    const watchWebsiteUrl = context.query.url as string;

    let icon: string | undefined;

    if (watchWebsiteUrl) {
      let formattedUrl = watchWebsiteUrl;

      if (!/^https?:\/\//i.test(watchWebsiteUrl)) {
        formattedUrl = `https://${watchWebsiteUrl}`;
      }

      try {
        store.dispatch(
          overviewUpdated({
            websiteUrl: formattedUrl,
          })
        );
        const response = await api.general.crawlIcon(formattedUrl);
        if (response && response[0] && response[0].icon !== null) {
          icon = response[0].icon;
        }

        const websiteResponse = await api.general.crawlWebsite(formattedUrl);
        if (websiteResponse && websiteResponse[0]) {
          const title = getWebsiteTitle(formattedUrl, websiteResponse[0].title);
          store.dispatch(overviewUpdated({ appName: title }));

          if (websiteResponse[0].navOptions[0]) {
            store.dispatch(
              uiUpdated({
                defaultSidebarMenuItems:
                  websiteResponse[0].navOptions[0].navLinks,
              })
            );
          }
        }
      } catch {
        // NO OP
      }

      store.dispatch(
        brandingUpdated({
          appIcon: {
            base: icon || undefined,
            ios: icon || undefined,
            android: icon || undefined,
          },
        })
      );
    }

    await Promise.all(baseApi.util.getRunningOperationPromises());

    return {
      props: {},
    };
  });

export default ProjectNewPage;
